import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useHeroData } from './hooks';

import { GLOBAL_STYLE_VARIABLES } from 'theme';
import { LAYOUT_VARIABLES } from 'components/Layout';
import { RichText } from 'components/Prismic';

interface IHeroSection {
  id: string;
}

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    box-sizing: border-box;
    display: grid;
    position: relative;
    row-gap: 2rem;
    align-content: center;
    background-color: ${colors.offWhite};
    padding-left: 43%;
    min-height: calc((1rem * var(${GLOBAL_STYLE_VARIABLES.fullScreenRatio})) + 1px);

    ${mq.md} {
      row-gap: 2.25rem;
      padding: calc(var(${LAYOUT_VARIABLES.headerHeight}) + 6.75rem) 2rem 9.5rem 2rem;
      min-height: initial;
    }
  `}
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;

    & > h1 {
      ${tp.useFont(fonts => fonts.Nimbus.regular)};

      line-height: 1.03;
      letter-spacing: -0.05em;
      color: ${colors.primary};
      font-size: 25rem;
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;

    & > h3 {
      line-height: 1.3;
      color: #eaeaeab3;
      font-size: 3rem;

      ${tp.useFont(fonts => fonts.Switzer.lightBold)};
    }
  `}
`;

const HeroSection: React.FC<IHeroSection> = ({ id }) => {
  const { title, description, heroImage } = useHeroData();

  return (
    <Container id={id}>
      {heroImage ? <BackgroundImage {...heroImage} /> : null}

      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const heroSectionFragment = graphql`
  fragment DiscoverHeroData on PrismicDiscover {
    data {
      hero_image {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      title {
        richText
      }
      description {
        richText
      }
    }
  }
`;

export default HeroSection;
