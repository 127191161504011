import { useMemo, useState } from 'react';

import { TFeaturedPlaylistsSection } from './FeaturedPlaylistsSection';

import { Normalize } from 'utils/types';
import { useNonconcurrentPlay } from 'utils/audioPlayer/useNonconcurrentPlay';
import { richTextWithPlaceholder } from 'utils/prismic';

export const useTopTwentyData = ({ primary, items }: TFeaturedPlaylistsSection) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { trackPlayerRefs, ...nonconcurrentPlayProps } = useNonconcurrentPlay();

  const { discover_featured_playlists_title: title } = primary;

  const tracksByPlaylist = useMemo(() => {
    return (
      items?.reduce((acm, { discover_featured_playlists_playlist: playlist }) => {
        if (!playlist.document) return acm;

        const { data } = playlist.document as Normalize<Queries.PrismicDiscoverPlaylist>;

        const { title, image, tracks } = data;

        const { gatsbyImageData, alt } = image;

        const normalizedTracks = tracks.map(
          ({
            track,
            track_name: trackName,
            track_artist: trackArtist,
            track_genres: trackGenres,
          }) => ({
            track: track.url,
            trackName: trackName ?? '',
            trackArtist: trackArtist ?? '',
            trackGenres: trackGenres ?? '',
          })
        );

        return [
          ...acm,
          {
            title: richTextWithPlaceholder(title, ''),
            titleText: title.text ?? '',
            image: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
            tracks: normalizedTracks,
          },
        ];
      }, []) ?? []
    );
  }, [items]);

  const composePlaylistClickHandle = index => () => {
    trackPlayerRefs.current = {};
    setActiveIndex(index);
  };

  return {
    title: richTextWithPlaceholder(title, ''),
    composePlaylistClickHandle,
    activeIndex,
    tracksByPlaylist,
    ...nonconcurrentPlayProps,
  };
};
