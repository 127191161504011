import { useDiscover } from '../DiscoverContext';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useHeroData = () => {
  const { title, description, hero_image: heroImage } = useDiscover().discover.data;

  const { gatsbyImageData, alt } = heroImage;
  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    heroImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
