import { useMemo } from 'react';

import { TTopTwentySection } from './TopTwentySection';

import { useNonconcurrentPlay } from 'utils/audioPlayer/useNonconcurrentPlay';
import { richTextWithPlaceholder } from 'utils/prismic';

export const useTopTwentyData = ({ primary, items }: TTopTwentySection) => {
  const { discover_top_twenty_title: title } = primary;

  const tracks = useMemo(() => {
    return (
      items.map(
        ({
          discover_top_twenty_track: track,
          discover_top_twenty_track_name: trackName,
          discover_top_twenty_track_artist: trackArtist,
          discover_top_twenty_track_genres: trackGenres,
        }) => ({
          track: track.url,
          trackName: trackName ?? '',
          trackArtist: trackArtist ?? '',
          trackGenres: trackGenres ?? '',
        })
      ) ?? []
    );
  }, [items]);

  return {
    title: richTextWithPlaceholder(title, ''),
    tracks,
    ...useNonconcurrentPlay(),
  };
};
