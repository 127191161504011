import { useDiscover } from './DiscoverContext';

import { useLayoutProps, TAlternateLanguages, FOOTER_LINK_TYPE } from 'components/Layout';
import { capitalize } from 'utils/helpers';

export const useDiscoverData = () => {
  const { lang, alternate_languages, data } = useDiscover().discover;

  const ids = { hero: 'hero', faq: 'faq' };

  const prependFooterLinks = [
    ...(data?.title.text
      ? [
          {
            label: capitalize(data.title.text),
            url: `#${ids.hero}`,
            type: FOOTER_LINK_TYPE.EXTERNAL,
            target: '',
          },
        ]
      : []),
    {
      label: 'FAQ',
      url: `#${ids.faq}`,
      type: FOOTER_LINK_TYPE.EXTERNAL,
      target: '',
    },
  ];

  return {
    layoutProps: useLayoutProps({
      lang,
      alternate_languages: alternate_languages as TAlternateLanguages,
      prependFooterLinks,
      isDark: true,
    }),
    data,
    ids,
  };
};
