import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withDiscoverProvider } from './DiscoverContext';
import { useDiscoverData } from './hooks';
import HeroSection from './HeroSection';
import Content from './Content';

import Faq from 'components/Faq';
import Layout from 'components/Layout';

const Discover: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps, data, ids } = useDiscoverData();

  return (
    <Layout {...layoutProps}>
      <HeroSection id={ids.hero} />
      <Content />
      <Faq id={ids.faq} data={data} />
    </Layout>
  );
};

export const discoverFragment = graphql`
  fragment DiscoverData on PrismicDiscover {
    ...DiscoverHeroData
    ...DiscoverContentData
    ...FaqData
  }

  fragment DiscoverLayoutData on PrismicDiscover {
    lang
    alternate_languages {
      document {
        ... on PrismicDiscover {
          lang
          url
        }
      }
    }

    data {
      title {
        text
      }
    }
  }
`;

export default withDiscoverProvider(Discover);
