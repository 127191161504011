import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Discover from './Discover';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.DiscoverQuery>('discover');

export const query = graphql`
  query Discover($id: String) {
    discover: prismicDiscover(id: { eq: $id }) {
      ...SEOData
      ...DiscoverData
      ...DiscoverLayoutData
      _previewable
    }
  }
`;

export type AllDiscoversQuery = Queries.AllDiscoversQuery;

export default withPrismicPreview(Discover);
