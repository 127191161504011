import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css, PropsOf } from '@emotion/react';

import contentMap from './contentMap';

import { useDiscover } from '../DiscoverContext';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: block;
    ${mq.md} {
      display: none;
    }
  `}
`;

const Content: React.FC = () => {
  const { content } = useDiscover().discover.data;

  return content.length ? (
    <Container>
      {content.map((contentData, index) => {
        const type = (contentData as { slice_type: string }).slice_type;
        const Section = contentMap[type];

        return Section ? (
          <Section key={`${type}${index}`} {...(contentData as PropsOf<typeof Section>)} />
        ) : null;
      })}
    </Container>
  ) : null;
};

export const contentFragment = graphql`
  fragment DiscoverContentData on PrismicDiscover {
    data {
      content {
        ...DiscoverContentFeaturedPlaylistsData
        ...DiscoverContentTopTwentyData
      }
    }
  }
`;

export default Content;
